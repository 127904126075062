<template>
  <div>
    <h1>Twoja strona do zarządzania płatnościami cyklicznymi</h1>
    <br>
    <h3>Zaloguj się aby kontynuować</h3>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  components: {
  },
}

</script>

<style>
</style>
