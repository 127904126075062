<template>
  <footer class="pt-5 my-5 text-body-secondary border-top">
    Created by * · © 2024
  </footer>
</template>
<script>
export default {
    name:'FooterComponent',
}
</script>

<style lang="">
</style>